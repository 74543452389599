<template>
  <el-card shadow="never">
    <el-form inline>
      <el-form-item>
        <el-input v-model="code" placeholder="内部归还单号" clearable style="width: 210px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="assetSn" placeholder="资产码" clearable style="width: 240px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changeFinish" :loading="loading">改为已上传</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "ChangeItemFinish",
  data() {
    return {
      code: '',
      assetSn: '',
      loading: false
    }
  },
  methods: {
    changeFinish() {
      if (!this.code) {
        return this.$message.error('内部归还单号不能为空！');
      }
      if (!this.assetSn) {
        return this.$message.error('资产码不能为空！');
      }
      this.code = this.code.trim();
      this.assetSn = this.assetSn.trim();

      this.$confirm('确定改为已上传状态吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.loading = true;
        this.$axios.post('returnOrder/changeItemFinish', {
          returnOrderCode: this.code,
          assetSn: this.assetSn,
        }).then(response => {
          this.loading = false;
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error(res.message);
          }

          this.code = ''
          this.assetSn = ''
          return this.$message.success('操作成功');

        }, error => {
          this.loading = false;
          return this.$message.error('操作失败，' + error.message);
        });
      })

    }
  }
}
</script>

<style scoped>

</style>